import React, { useEffect, useState } from 'react'
import HeroSlider from '../components/HeroSlider'
import axios from 'axios';

const Home = () => {
    const [breakingNews, setBreakingNews] = useState([]);
    const [economy, setEconomy] = useState([]);
    const [sports, setSports] = useState([]);
    const [politics, setPolitics] = useState([]);
    const [sliderNews, setSliderNews] = useState([]);

    const fetchArticlesByCategoryAndCount = async (slug, count) => {
        return await axios.get(`${process.env.REACT_APP_API_URL}/category/${slug}/${count}/articles`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }

    const fetchSliderNews = async () => {
        await fetchArticlesByCategoryAndCount("latest news slider", 8)
            .then(response => {
                setSliderNews(response);
            }).catch(error => {
                console.error('There was an error fetching the posts!', error);
            });
    }

    const fetchBreakingNews = async () => {
        await fetchArticlesByCategoryAndCount("breaking news", 8)
            .then(response => {
                setBreakingNews(response);
            }).catch(error => {
                console.error('There was an error fetching the posts!', error);
            });
    }

    const fetchEconomy = async () => {
        await fetchArticlesByCategoryAndCount("economy", 6)
            .then(response => {
                setEconomy(response);
            }).catch(error => {
                console.error('There was an error fetching the posts!', error);
            });
    }

    const fetchSports = async () => {
        await fetchArticlesByCategoryAndCount("Sports", 6)
            .then(response => {
                setSports(response);
            }).catch(error => {
                console.error('There was an error fetching the posts!', error);
            });
    }
    const fetchPolitics = async () => {
        await fetchArticlesByCategoryAndCount("Politics", 5)
            .then(response => {
                setPolitics(response);
            }).catch(error => {
                console.error('There was an error fetching the posts!', error);
            });
    }

    const cleanContent = (content) => {
        return content
            .replace(/<.*?>/g, '')  // Remove HTML elements
            .replace(/<!--.*?-->/g, '')  // Remove HTML comments
            .replace(/\[metadata element\s*=\s*".*?"\]/g, '');  // Remove metadata elements
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        fetchSliderNews();
        fetchBreakingNews();
        fetchEconomy();
        fetchSports();
        fetchPolitics();
    }, [])

    return (
        <>
            <div className='w-full bg-slate-400'>
                <iframe
                    src="https://www.youtube.com/embed/QA28yAXHB6U"
                    className="w-full h-[300px] lg:h-screen"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            <div className='mx-3 my-10 lg:mx-40'>
                <HeroSlider data={sliderNews} />
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 mx-3 lg:mx-40 mb-8'>
                <div className='col-span-2'>
                    {/* BREAKING NEWS */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-4xl uppercase my-8 p-1'>Breaking News</h2>
                        <div className='grid grid-cols-1 lg:flex lg:flex-wrap gap-8'>
                            {

                                breakingNews.map((item, index) => {
                                    const cleanedContent = cleanContent(item.content);
                                    const formattedDate = formatDate(item.published_at);

                                    if (index < 2) {
                                        return (
                                            <div className='lg:w-[calc(50%-1rem)] flex flex-col'>
                                                <div className='relative'>
                                                    <img className='w-full' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" />
                                                    <span className='absolute bottom-2 left-2 bg-red-500 text-white text-sm rounded px-2'>Breaking News</span>
                                                </div>
                                                <h2 className='text-2xl my-2'><a className='hover:text-red-500' href={`/article/${item.slug}`}>{item.title.slice(0, 75)}...</a></h2>
                                                <p className='text-sm'><strong>{item.user?.name}</strong> - <span className='text-gray-500'>{formattedDate}</span></p>
                                                <p className='text-gray-500 text-sm mt-2'>{cleanedContent.slice(0, 100)}...</p>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className='lg:w-[calc(50%-1rem)] flex gap-2 items-center'>
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" className='w-[100px] h-[80px] object-cover' />
                                                <span className='flex flex-col gap-1'>
                                                    <a href={`/article/${item.slug}`} className='text-sm hover:text-red-500'>{item.title}</a>
                                                    <span className='text-gray-400 text-sm'>{formattedDate}</span>
                                                </span>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    {/* END BREAKING NEWS */}

                    {/* ECONOMY */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-4xl uppercase my-8 p-1'>Economy</h2>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                            {
                                economy.map((item, index) => {
                                    const cleanedContent = cleanContent(item.content);
                                    const formattedDate = formatDate(item.published_at);
                                    if (index === 0) {
                                        return (
                                            <div key={item.id} className='flex flex-col'>
                                                <div className='relative'>
                                                    <img className='w-full' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" />
                                                    <span className='absolute bottom-2 left-2 bg-red-500 text-white text-sm rounded px-2'>Breaking News</span>
                                                </div>
                                                <h2 className='text-2xl my-2'>
                                                    <a className='hover:text-red-500' href={`/article/${item.slug}`}>{item.title.slice(0, 75)}...</a>
                                                </h2>
                                                <p className='text-sm'>
                                                    <strong>{item.user?.name}</strong> - <span className='text-gray-500'>{formattedDate}</span>
                                                </p>
                                                <p className='text-gray-500 text-sm mt-2'>{cleanedContent.slice(0, 100)}...</p>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                            <div className='flex flex-col gap-8'>
                                {
                                    economy.map((item, index) => {
                                        const formattedDate = formatDate(item.published_at);
                                        if (index !== 0) {
                                            return (
                                                <div key={item.id} className='flex gap-2'>
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" className='w-[100px] h-[80px] object-cover' />
                                                    <span className='flex flex-col gap-1'>
                                                        <a href={`/article/${item.slug}`} className='text-sm hover:text-red-500'>{item.title.slice(0, 75)}...</a>
                                                        <span className='text-gray-400 text-sm'>{formattedDate}</span>
                                                    </span>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        </div>

                    </div>
                    {/* END ECONOMY */}

                    {/* SPORTS */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-4xl uppercase my-8 p-1'>Sports</h2>
                        <div className='grid grid-cols-1 lg:grid-cols-3  gap-8'>
                            {
                                sports.map((item, index) => {
                                    return (
                                        <div className='flex flex-col'>
                                            <div className='relative'>
                                                <img className='w-full' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" />
                                                <span className='absolute bottom-2 left-2 bg-red-500 text-white text-sm rounded px-2'>Breaking News</span>
                                            </div>
                                            <h2 className='mt-2'><a className='hover:text-red-500' href={`/article/${item.slug}`}>{item.title.slice(0, 75)}...</a></h2>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* END SPORTS */}

                    {/* POLITICS */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-4xl uppercase my-8 p-1'>Politics</h2>
                        <div className='grid grid-cols-1 gap-8'>
                            {
                                politics.map((item, index) => {
                                    const cleanedContent = cleanContent(item.content);
                                    const formattedDate = formatDate(item.published_at);
                                    return (
                                        <div className='grid grid-cols-3 gap-2 lg:gap-8'>
                                            <div>
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" className='w-full object-cover' />
                                            </div>
                                            <div className='col-span-2 flex flex-col'>
                                                <a href={`/article/${item.slug}`} className='lg:text-2xl hover:text-red-500'>{item.title.slice(0, 75)}...</a>
                                                <p className='text-sm'><strong>{item.user?.name}</strong> - <span className='text-gray-500'>{formattedDate}</span></p>
                                                <p className='hidden lg:block text-gray-500 text-sm mt-2'>{cleanedContent.slice(0, 75)}...</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* END POLITICS */}

                </div>
                <div>
                    {/* LIVE TV */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-4xl uppercase my-8 p-1'>Live Tv</h2>
                        <div className='grid grid-cols-1'>
                            <h2 className='text-2xl'>Live TV</h2>
                            <div className='text-sm text-gray-500'><span className='font-semibold'>By Nerold</span> - May 2, 2024</div>
                            <div className='relative mt-3'>
                                <iframe className='w-full' src="https://www.youtube.com/embed/XUlNw0_vXS8" title="Sandeep Chaudhary Live : CM Yogi की कुर्सी पर Abhay Dubey का बड़ा खुलासा । Lok Sabha Election । BJP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                <div className='absolute bottom-2 left-2 bg-red-500 text-sm text-white px-2 rounded animate-pulse'>Live TV</div>
                            </div>
                        </div>
                    </div>
                    {/* END LIVE TV */}

                    {/* TATKAL SAMACHAR */}
                    <div>
                        <h2 className='bg-red-600 text-white text-center text-2xl uppercase my-8 p-1'>Tatkal Samachar</h2>
                        <div className='grid grid-cols-1'>
                            <iframe className='w-full' src="https://www.youtube.com/embed/XUlNw0_vXS8" title="Sandeep Chaudhary Live : CM Yogi की कुर्सी पर Abhay Dubey का बड़ा खुलासा । Lok Sabha Election । BJP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='grid grid-cols-1 mt-8'>
                            <iframe className='w-full' src="https://www.youtube.com/embed/XUlNw0_vXS8" title="Sandeep Chaudhary Live : CM Yogi की कुर्सी पर Abhay Dubey का बड़ा खुलासा । Lok Sabha Election । BJP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='grid grid-cols-1 mt-8'>
                            <iframe className='w-full' src="https://www.youtube.com/embed/XUlNw0_vXS8" title="Sandeep Chaudhary Live : CM Yogi की कुर्सी पर Abhay Dubey का बड़ा खुलासा । Lok Sabha Election । BJP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='grid grid-cols-1 mt-8'>
                            <iframe className='w-full' src="https://www.youtube.com/embed/XUlNw0_vXS8" title="Sandeep Chaudhary Live : CM Yogi की कुर्सी पर Abhay Dubey का बड़ा खुलासा । Lok Sabha Election । BJP" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                    {/* END TATKAL SAMACHAR */}
                </div>
            </div>

        </>
    )
}

export default Home