import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const Login = () => {
    const [inputs, setInputs] = useState(
        {
            email: "",
            password: ""
        }
    )
    const [err, setErr] = useState(null)
    const { login } = useContext(AuthContext);
    const navigate = useNavigate()

    const handleChange = (e) => {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            await login(inputs)
            navigate("/")
        }
        catch (err) {
            setErr(err.response.data)
        }
    }

    return (
        <>
            <h1>Login</h1>
            <form>
                <label>Your Email :</label>
                <input type='email' placeholder='enter your Email' name='email' onChange={handleChange} /> <br />

                <label>Your Password : </label>
                <input type='password' placeholder='enter your Password' name='password' onChange={handleChange} />
                {err && err} <br />
                <button onClick={handleLogin}>Login</button>

            </form>
        </>
    )
}

export default Login