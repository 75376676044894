import {
  RouterProvider,
  Outlet,
  createBrowserRouter
} from "react-router-dom";
import Home from './pages/Home';
import Category from './pages/Category';
import Post from './pages/Post';
import Search from './pages/Search';

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import { useEffect } from "react";
import axios from "axios";
import Login from "./pages/Login";


function App() {

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_URL}/visitor`);
  }, []);

  const ClientLayout = () => {
    return (
      <>
        <Navbar />
        <>
          <Outlet />
        </>
        <Footer />
        <ScrollToTop />
      </>
    )
  }

  const router = createBrowserRouter([
    {
      element: <ClientLayout />,
      children: [
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/:slug/articles",
          element: <Category />
        },
        {
          path: "/article/:slug",
          element: <Post />
        },
        {
          path: "/search/articles",
          element: <Search />
        },
        {
          path: "/login",
          element: <Login />
        },

      ]
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
