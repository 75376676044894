import React, { useState } from 'react';
import Pagination from './Pagination';

const itemsPerPage = 10; // Number of items per page

const CategoryPagination = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate the current items to display
    const currentItems = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const cleanContent = (content) => {
        return content
            .replace(/<.*?>/g, '')  // Remove HTML elements
            .replace(/<!--.*?-->/g, '')  // Remove HTML comments
            .replace(/\[metadata element\s*=\s*".*?"\]/g, '');  // Remove metadata elements
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    return (
        <>
            {/* Render current items */}
            {currentItems.map((item, index) => {
                const cleanedContent = cleanContent(item.content);
                const formattedDate = formatDate(item.published_at);
                return (
                    <div className='flex flex-col'>
                        <div className='relative'>
                            <img className='w-full' src={item.medias?.length > 0 && `${process.env.REACT_APP_ASSETS_API_URL}/uploads/article/${item.medias[0].file_name}`} alt="" />
                            <span className='absolute bottom-2 left-2 bg-red-500 text-white text-sm rounded px-2'>{item.category?.name}</span>
                        </div>
                        <h2 className='mt-2'><a className='hover:text-red-500' href={`/article/${item.slug}`}>{item.title.slice(0, 75)}...</a></h2>
                        <div className='flex items-center justify-between'>
                            <div className='text-sm mt-3'>
                                <span className='font-bold'>{item.user?.name}</span>
                                - {formattedDate}
                            </div>
                            <div className='bg-gray-950 text-white w-[30px] h-[30px] flex items-center justify-center'>
                                {item.read_count}
                            </div>
                        </div>
                    </div>
                )
            }
            )}

            {/* Pagination controls */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </>
    );
};

export default CategoryPagination;
