import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchComponent from '../components/SearchComponent';
import PopularArticles from '../components/PopularArticles';

const Search = ({ searchTerm }) => {
    const [articles, setArticles] = useState([]);
    const [searchInput, setSearchInput] = useState("")
    const [mostReadArticles, setMostReadArticles] = useState([]);

    const fetchArticles = async (searchTerm) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/search/articles/${searchTerm}`)
            .then(response => {
                if (response.status == 200) {
                    setArticles(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const fetchMostReadArticles = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/articles/read`)
            .then(response => {
                if (response.status == 200) {
                    setMostReadArticles(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchArticles(searchInput)
    }

    useEffect(() => {
        fetchArticles(searchTerm);
        fetchMostReadArticles();
    }, []);

    return (
        <div className='lg:mx-40 mx-3'>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-8">
                <div className='col-span-2'>
                    <div className="">
                        <h1 className='text-4xl mb-3'>Search Results</h1>
                        <h2 className='text-2xl text-gray-500 italic'>{searchTerm}</h2>
                    </div>
                    <div className="my-8">
                        <form onSubmit={handleSubmit} className='flex border' action="">
                            <input className='flex-1 p-2' type="text" name='searchInput' onChange={(e) => setSearchInput(e.target.value)} placeholder='Search...' />
                            <button onClick={handleSubmit} className='p-2 bg-red-500 text-white'>Search</button>
                        </form>
                    </div>
                    <div className="my-8 space-y-3">
                        {/* {
                            articles.map((item) => {
                                const cleanedContent = cleanContent(item.content);
                                const formattedDate = formatDate(item.published_at);
                                return (
                                    <div className='grid grid-cols-3 gap-2 lg:gap-8'>
                                        <div>
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWz39HqWj8sBJ3Pm2gBXcSsqoNFaKOJSAB2w&s" alt="" className='w-full object-cover' />
                                        </div>
                                        <div className='col-span-2 flex flex-col'>
                                            <a href="/post" className='lg:text-2xl hover:text-red-500'>{item.title.slice(0, 70)}...</a>
                                            <p className='text-sm'><strong>{item.user?.name}</strong> - <span className='text-gray-500'>{formattedDate}</span></p>
                                            <p className='hidden lg:block text-gray-500 text-sm mt-2'>{cleanedContent.slice(0, 100)}...</p>
                                        </div>
                                    </div>
                                )
                            })
                        } */}
                        <SearchComponent data={articles} />
                    </div>
                </div>
                <div className='self-start grid grid-cols-1 gap-8'>

                    <PopularArticles />
                </div>
            </div>
        </div>
    )
}

export default Search