import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CategoryPagination from '../components/CategoryPagination';
import { useLocation } from 'react-router-dom';
import PopularArticles from '../components/PopularArticles';

const Category = () => {
    const [category, setCategory] = useState({});
    const [articles, setArticles] = useState([]);
    const categorySlug = useLocation().pathname.split("/")[1]
    const [mostReadArticles, setMostReadArticles] = useState([]);

    const fetchArticlesByCategory = async (slug) => {
        return await axios.get(`${process.env.REACT_APP_API_URL}/category/${slug}/articles`)
            .then(response => {
                setArticles(response.data);
            })
            .catch(error => {
                throw error;
            });
    }

    const fetchMostReadArticles = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/articles/read`)
            .then(response => {
                if (response.status == 200) {
                    setMostReadArticles(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const fetchCategory = async (slug) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/category/${slug}/get`)
            .then(response => {
                if (response.status == 200) {
                    setCategory(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    useEffect(() => {
        fetchArticlesByCategory(categorySlug);
        fetchMostReadArticles();
        fetchCategory(categorySlug);
    }, [])

    return (
        <div className='lg:mx-40 mx-3'>
            <div className='my-8'>
                <h1 className='text-4xl font-semibold'>{category?.name}</h1>
            </div>
            <div className='grid grid-cols-1 gap-2 my-8'>
                <div className="lg:h-[400px] relative">
                    <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                    <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                        <div className='text-white bg-black px-2 inline-block uppercase text-sm'>Shimla</div>
                        <h1 className="text-white lg:text-2xl mt-3"><a className='hover:text-red-500' href="/post">Shimla News : प्रदेशहित के सरकार के हर फैंसले के साथ खड़ी हूँ: प्रतिभा सिंह.</a></h1>
                        <div className='text-white text-sm mt-3'>
                            <span className='font-bold'>Neha Sood</span>
                            - July 13, 2024
                        </div>
                    </div>
                </div>
                <div className="flex overflow-x-auto gap-2">
                    <div className="h-[200px] min-w-[300px] lg:w-full relative">
                        <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                            <div className='text-white bg-black px-2 inline-block uppercase text-sm'>Shimla</div>
                            <h1 className="text-white mt-3"><a className='hover:text-red-500' href="/post">Shimla News : प्रदेशहित के सरकार के हर फैंसले के साथ खड़ी हूँ: प्रतिभा सिंह.</a></h1>
                        </div>
                    </div>
                    <div className="h-[200px] min-w-[300px] lg:w-full relative">
                        <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                            <div className='text-white bg-black px-2 inline-block uppercase text-sm'>Shimla</div>
                            <h1 className="text-white mt-3"><a className='hover:text-red-500' href="/post">Shimla News : प्रदेशहित के सरकार के हर फैंसले के साथ खड़ी हूँ: प्रतिभा सिंह.</a></h1>
                        </div>
                    </div>
                    <div className="h-[200px] min-w-[300px] lg:w-full relative">
                        <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                            <div className='text-white bg-black px-2 inline-block uppercase text-sm'>Shimla</div>
                            <h1 className="text-white mt-3"><a className='hover:text-red-500' href="/post">Shimla News : प्रदेशहित के सरकार के हर फैंसले के साथ खड़ी हूँ: प्रतिभा सिंह.</a></h1>
                        </div>
                    </div>
                    <div className="h-[200px] min-w-[300px] lg:w-full relative">
                        <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                            <div className='text-white bg-black px-2 inline-block uppercase text-sm'>Shimla</div>
                            <h1 className="text-white mt-3"><a className='hover:text-red-500' href="/post">Shimla News : प्रदेशहित के सरकार के हर फैंसले के साथ खड़ी हूँ: प्रतिभा सिंह.</a></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-start grid grid-cols-1 lg:grid-cols-3 gap-8 my-8">
                <div className='col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-8'>
                    <CategoryPagination data={articles} />
                </div>
                <div className='self-start col-span-1 grid grid-cols-1 gap-8'>
                    <PopularArticles />

                </div>
            </div>
        </div>
    )
}

export default Category