import React, { useEffect, useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import axios from 'axios';

const popular_posts = [
    { title: 'शिमला :- राज्य प्रदूषण नियंत्रण बोर्ड द्वारा ‘हिमाचल एनवायरो प्रश्नोत्तरी’ प्रतियोगिता...', link: '/post', date: "May 10, 2024", img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-xlWiW713Pfyij50-7QIC0iGsr4cpPHThlg&s" },
    { title: 'प्रेस क्लब सोलन की ओर से इन दिनों क्रिकेट लीग का...', link: '/post', date: "May 10, 2024", img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-xlWiW713Pfyij50-7QIC0iGsr4cpPHThlg&s" },
    { title: 'Kangra News: भाजपा वाले नकली गोरक्षक, हम कर रहे गोसंरक्षण :...', link: '/post', date: "May 10, 2024", img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-xlWiW713Pfyij50-7QIC0iGsr4cpPHThlg&s" },
];

const Footer = () => {
    const [visitorCount, setVisitorCount] = useState(0)
    const [popularCategories, setPopularCategories] = useState([
        { name: 'Breaking News', slug: 'breaking-news', link: '/category', total: 0 },
        { name: 'हिमाचल प्रदेश', slug: 'himachal-pradesh', link: '/category', total: 0 },
        { name: 'India', slug: 'india', link: '/category', total: 0 },
        { name: 'सोलन', slug: 'solan', link: '/category', total: 0 },
        { name: 'Politics', slug: 'politics', link: '/category', total: 0 },
        { name: 'Election', slug: 'election', link: '/category', total: 0 },
        { name: 'भारत', slug: 'bharat', link: '/category', total: 0 },
        { name: 'Live TV', slug: 'live-tv', link: '/category', total: 0 },
    ]);
    const [footerCategories, setFooterCategories] = useState([

        { name: 'Politics', slug: 'politics' },
        { name: 'Business', slug: 'business' },
        { name: 'Travel Updates', slug: 'travel-updates' },
        { name: 'Technology', slug: 'technology' },
        { name: 'Sports', slug: 'sports' },
        { name: 'Election', slug: 'election' },

    ]);

    const fetchCategoryCounts = async () => {
        try {
            const updatedCategories = await Promise.all(
                popularCategories.map(async (category) => {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/footer/category/${category.slug}`);
                    return {
                        ...category,
                        total: response.data,
                    };
                })
            );
            console.log(updatedCategories)
            setPopularCategories(updatedCategories);
        } catch (error) {
            console.error('There was an error fetching the category counts!', error);
        }
    };

    const fetchTotalVisitors = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/visitors/count`)
            .then(response => {
                setVisitorCount(response.data.visitor_count);
                console.log('Visitor count:', response.data.visitor_count);
                // You can set this count to state and display it
            })
            .catch(error => {
                console.error('There was an error fetching the visitor count!', error);
            });
    }

    useEffect(() => {
        fetchCategoryCounts();
        fetchTotalVisitors();

    }, []);

    return (
        <>
            <div className='bg-gray-950 py-10'>
                <div className="mx-3 lg:mx-40">
                    <h2 className='text-red-500 text-2xl text-center uppercase mb-3'>Total Visitors</h2>
                    <h1 className='text-white font-bold text-6xl text-center uppercase animate-pulse'>{visitorCount}</h1>
                </div>
            </div>
            <footer className="bg-gray-800 text-white py-10">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-3 lg:mx-40">
                    {/* Brand Logo and Details */}
                    <div>
                        <h2 className="text-4xl text-red-500 font-bold mb-10"><a href="/">तत्काल समाचार</a></h2>
                        <div className='flex flex-wrap gap-14'>

                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><WhatsAppIcon /></a>
                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><InstagramIcon /></a>
                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><FacebookIcon /></a>
                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><XIcon /></a>
                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><YouTubeIcon /></a>
                            <a href="#" className='hover:text-red-500 p-2 border rounded bg-gray-900 hover:border-red-500'><LinkedInIcon /></a>
                        </div>
                    </div>

                    {/* Popular Posts */}
                    {/* <div>
                        <h2 className="text-2xl font-bold mb-10">Popular Posts</h2>
                        <ul>
                            {popular_posts.map((item, index) => (
                                <li key={index} className='flex gap-2 mb-2'>
                                    <img src={item.img} alt="" className='w-[80px] h-[80px]' />
                                    <span className='flex flex-col gap-2'>
                                        <a href={item.link} className='text-sm hover:text-red-500'>{item.title}</a>
                                        <span className='text-gray-400 text-sm'>{item.date}</span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div> */}

                    {/* Popular Categories */}
                    <div>
                        <h2 className="text-2xl font-bold mb-10">Popular Categories</h2>
                        <ul>
                            {/* Map through updated popularCategories array to display categories */}
                            {popularCategories.map((item, index) => (
                                <li key={item.slug} className='mb-2'>
                                    <a href={item.link} className="hover:text-red-500 flex justify-between">
                                        {item.name} <span>{item.total}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </footer>
            <div className='bg-gray-950 text-white text-sm py-3'>
                <div className='flex flex-col-reverse lg:flex-row justify-between gap-3 mx-4'>
                    <div className='text-center'>&copy; Copyright 2024 - <span className='text-red-500 font-semibold'>Tatkal Samachar</span> - All rights reserved</div>
                    <ul className='flex flex-wrap justify-center lg:flex-nowrap space-x-3'>
                        {
                            footerCategories.map((item) => {
                                return (

                                    <li><a className='hover:text-red-500' href={`/${item.slug}/articles`}>{item.name}</a></li>
                                )
                            })
                        }

                    </ul>
                </div>
            </div>
        </>
    );
};

export default Footer;
