import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSlider = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const cleanContent = (content) => {
        return content
            .replace(/<.*?>/g, '')  // Remove HTML elements
            .replace(/<!--.*?-->/g, '')  // Remove HTML comments
            .replace(/\[metadata element\s*=\s*".*?"\]/g, '');  // Remove metadata elements
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    return (
        <div className="relative">
            <Slider {...settings}>
                {
                    data.map((item, index) => {
                        const cleanedContent = cleanContent(item.content);
                        const formattedDate = formatDate(item.published_at);
                        return (
                            <div className="lg:h-screen h-[200px] relative">
                                <img className='w-full h-full object-cover' src="https://images.pexels.com/photos/346529/pexels-photo-346529.jpeg" alt="" />
                                <div className="absolute bottom-0 left-0 bg-gradient-to-t from-black via to-transparent bg-opacity-50 p-3">
                                    <div className='text-white bg-black px-2 inline-block uppercase text-sm'>{item.category?.name}</div>
                                    <h1 className="text-white lg:text-4xl mt-3"><a className='hover:text-red-500' href={`/article/${item.slug}`}>{item.title.slice(0, 75)}...</a></h1>
                                    <div className='text-white text-sm mt-3'>
                                        <span className='font-bold'>{item.user?.name}</span>
                                        - {formattedDate}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default HeroSlider