import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import axios from 'axios';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import PopularArticles from '../components/PopularArticles';

const Post = () => {
    const slug = useLocation().pathname.split("/")[2]
    const [article, setArticle] = useState({});
    const [mostReadArticles, setMostReadArticles] = useState([]);
    const [comment, setComment] = useState('');
    const navigate = useNavigate(); // Initialize the navigate function

    const handleAddComment = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('accessToken'); // Assuming the JWT token is stored in localStorage
            if (!token) {
                navigate('/login'); // Redirect to login if token is not found
                return;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/articles/${article.id}/comments`,
                { comment },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Comment added:', response.data);
            setComment(''); // Clear the input after successful submission
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };


    const fetchArticle = async (slug) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/article/${slug}`)
            .then(response => {
                if (response.status == 200) {
                    setArticle(response.data)
                    console.log(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const fetchMostReadArticles = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/articles/read`)
            .then(response => {
                if (response.status == 200) {
                    setMostReadArticles(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        fetchArticle(slug);
        fetchMostReadArticles();
    }, []);

    return (
        <div className='lg:mx-40 mx-3'>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-8">
                <div className='col-span-2'>
                    <div className='space-x-2'>
                        {
                            article.categories?.map((item) => {
                                return (
                                    <a href={`/${item.slug}/articles`} key={item.slug} className='bg-gray-950 text-white text-sm px-2 py-1'>{item.name}</a>
                                )
                            })
                        }
                    </div>
                    <div className='my-8'>
                        <h1 className='lg:text-4xl text-2xl'>{article.title}</h1>
                        <div className='flex justify-between items-center mt-3'>
                            <p className='text-sm'>By <span className='font-semibold'>{article.user?.name}</span> - <span className='text-gray-500'>{formatDate(article.published_at)}</span></p>
                            <div className='text-sm space-x-2'>
                                <span><VisibilityIcon /> {article.read_count}</span>
                                <span><ChatBubbleOutlineIcon /> {article.comments?.length > 1 ? article.comments.length : "0"}</span>
                            </div>
                        </div>
                    </div>
                    {
                        // {`${process.env.REACT_APP_API_URL}/uploads/article/${media.file_name}`}
                        article.medias?.length > 0 &&
                        <>
                            {
                                article.medias.map((media, index) => {
                                    if (index === 0) {
                                        return (
                                            <div className='w-full my-4'>
                                                <img className='w-full h-[300px] object-cover' src={`${process.env.REACT_APP_ASSETS_API_URL}/uploads/article/${media.file_name}`} alt="" />
                                            </div>
                                        )
                                    }
                                })

                            }
                            <div className="flex overflow-x-auto gap-2 my-4">
                                {
                                    article.medias.map((media, index) => {
                                        if (index !== 0) {
                                            return (
                                                <div className="h-[200px] min-w-[300px] lg:w-full">
                                                    <img className='w-full h-full object-cover' src={`${process.env.REACT_APP_ASSETS_API_URL}/uploads/article/${media.file_name}`} alt="" />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>



                        </>
                    }
                    <div className='space-y-3 my-8 w-full break-all' dangerouslySetInnerHTML={{ __html: article.content }}>

                    </div>
                    <div className='flex items-center flex-wrap text-sm font-semibold space-x-2 space-y-2 my-8'>
                        <span className='bg-gray-950 px-2 py-1 text-white uppercase'>Tags</span>
                        {
                            article.tags?.map((item) => {
                                return (
                                    <a key={item.slug} href={`/${item.slug}/articles`} className='border hover:bg-red-500 hover:text-white px-2 py-1'>{item.name}</a>
                                )
                            })
                        }
                    </div>
                    <div className='my-8'>
                        {
                            article.user &&
                            <>
                                <h2 className='text-red-500 border-red-500 border-b-2 text-2xl uppercase'>Author</h2>
                                <div className='lg:flex lg:items-center gap-8 mt-4'>
                                    <img className='w-[100px] h-[100px] rounded-full bg-gray-200 object-cover' src="" alt="" />
                                    <div className='space-y-3'>
                                        <h3 className='font-bold'>{article.user.name}</h3>
                                        <p className='text-gray-500'>{article.user.email}</p>
                                    </div>
                                </div>

                            </>
                        }
                    </div>
                    <div className="my-8">
                        <h2 className='text-red-500 border-red-500 border-b-2 text-2xl uppercase mb-4'>Leave a Reply</h2>
                        <form onSubmit={handleAddComment} className='flex flex-col gap-3'>
                            <input className='border p-2' name="reply_comment" placeholder='Comment' onChange={(e) => setComment(e.target.value)} />
                            <button onClick={handleAddComment} className='w-[150px] bg-red-500 text-white text-center p-2'>Post Comment</button>
                        </form>
                    </div>
                </div>
                <div className='self-start grid grid-cols-1 gap-8'>
                    <PopularArticles />
                </div>
            </div>
        </div>
    )
}

export default Post