import axios from 'axios';
import React, { useEffect, useState } from 'react'

const PopularArticles = () => {
    const [mostReadArticles, setMostReadArticles] = useState([]);

    const fetchMostReadArticles = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/articles/read`)
            .then(response => {
                if (response.status == 200) {
                    setMostReadArticles(response.data)
                }
            })
            .catch(error => {
                console.error('There was an error fetching the article!', error);
            })
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        fetchMostReadArticles();
    }, []);

    return (
        <>
            <div className=''>
                <h2 className='text-2xl bg-red-500 text-white text-center font-bold uppercase p-2 block'>Most Read</h2>
            </div>
            {
                mostReadArticles.map((item) => {
                    const formattedDate = formatDate(item.published_at);
                    return (
                        <div key={item.slug} className='grid grid-cols-3 gap-2'>
                            <div>
                                <img src={item.medias?.length > 0 && `${process.env.REACT_APP_ASSETS_API_URL}/uploads/article/${item.medias[0].file_name}`} />
                            </div>
                            <div className='col-span-2 flex flex-col'>
                                <a href={`/article/${item.slug}`} className=' hover:text-red-500'>{item.title.slice(0, 70)}...</a>
                                <p className='text-sm mt-2'><span className='text-gray-500'>{formattedDate}</span></p>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default PopularArticles