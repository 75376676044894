import React, { useState } from 'react';
import Pagination from './Pagination';

const itemsPerPage = 10; // Number of items per page

const SearchComponent = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate the current items to display
    const currentItems = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const cleanContent = (content) => {
        return content
            .replace(/<.*?>/g, '')  // Remove HTML elements
            .replace(/<!--.*?-->/g, '')  // Remove HTML comments
            .replace(/\[metadata element\s*=\s*".*?"\]/g, '');  // Remove metadata elements
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    return (
        <div>
            {/* Render current items */}
            {currentItems.map((item, index) => {
                const cleanedContent = cleanContent(item.content);
                const formattedDate = formatDate(item.published_at);
                return (
                    <div className='grid grid-cols-3 gap-2 lg:gap-8 mb-3'>
                        <div>
                            <img src={item.medias?.length > 0 && `${process.env.REACT_APP_ASSETS_API_URL}/uploads/article/${item.medias[0].file_name}`} />
                        </div>
                        <div className='col-span-2 flex flex-col'>
                            <a href={`/article/${item.slug}`} className='lg:text-2xl hover:text-red-500'>{item.title.slice(0, 70)}...</a>
                            <p className='text-sm'><strong>{item.user?.name}</strong> - <span className='text-gray-500'>{formattedDate}</span></p>
                            <p className='hidden lg:block text-gray-500 text-sm mt-2'>{cleanedContent.slice(0, 100)}...</p>
                        </div>
                    </div>
                )
            }
            )}

            {/* Pagination controls */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default SearchComponent;
