import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5; // Adjust this number to control how many pages are visible at once
        let startPage, endPage;

        if (totalPages <= maxPagesToShow) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
                startPage = 1;
                endPage = maxPagesToShow;
            } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
                startPage = totalPages - maxPagesToShow + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - Math.floor(maxPagesToShow / 2);
                endPage = currentPage + Math.floor(maxPagesToShow / 2);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <div className="flex justify-center mt-4">
            <button
                className={`px-4 py-2 mx-1 rounded ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'bg-gray-300 hover:bg-gray-400'
                    }`}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>

            {currentPage > Math.ceil(5 / 2) && (
                <>
                    <button
                        className="px-4 py-2 mx-1 rounded bg-gray-300 hover:bg-gray-400"
                        onClick={() => onPageChange(1)}
                    >
                        1
                    </button>
                    {currentPage > Math.ceil(5 / 2) + 1 && <span className="px-2 py-2">...</span>}
                </>
            )}

            {generatePageNumbers().map((page) => (
                <button
                    key={page}
                    className={`px-4 py-2 mx-1 rounded ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400'
                        }`}
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </button>
            ))}

            {currentPage + Math.floor(5 / 2) < totalPages && (
                <>
                    {currentPage + Math.floor(5 / 2) < totalPages && <span className="px-2 py-2">...</span>}
                    <button
                        className="px-4 py-2 mx-1 rounded bg-gray-300 hover:bg-gray-400"
                        onClick={() => onPageChange(totalPages)}
                    >
                        {totalPages}
                    </button>
                </>
            )}

            <button
                className={`px-4 py-2 mx-1 rounded ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'bg-gray-300 hover:bg-gray-400'
                    }`}
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;
