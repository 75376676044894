import React, { useState } from 'react'
import FaBars from '@mui/icons-material/Menu';
import FaTimes from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const categories = [
  { name: 'देश', link: '#', subcategories: ['हिमाचल प्रदेश'] },
  { name: 'विदेश', link: '/विदेश/articles', },
  { name: 'राजनीति', link: '/राजनीति/articles' },
  { name: 'बॉलीवुड', link: '/बॉलीवुड/articles' },
  { name: 'रोजगार', link: '/रोजगार/articles' },
  { name: 'क्लासिफाइड', link: '/क्लासिफाइड/articles' },
  { name: 'ज्योतिष', link: '/ज्योतिष/articles' },
  { name: 'दिव्ययोग', link: '/दिव्ययोग/articles' },
  { name: 'वायरल वीडियो', link: '/वायरल वीडियो/articles' },
  { name: 'Live TV', link: '/live-tv/articles' },
];


const Navbar = () => {
  const [searchInput, setSearchInput] = useState("")
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const navigate = useNavigate();


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search/articles");
    setSearchOpen(false);
  }


  return (
    <>
      <div>
        <nav className="bg-gray-800 p-4">
          <div className="container mx-auto flex justify-between items-center">
            {/* Menu Icon */}
            <div className="lg:hidden">
              <button onClick={toggleMenu} className="text-white focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>

            {/* Logo */}
            <div className="hidden flex-1 lg:flex justify-center lg:justify-start">
            </div>

            {/* Logo */}
            <div className="flex-1 flex justify-center">
              <a href="/" className="text-white text-2xl font-bold animate-pulse">तत्काल समाचार</a>
            </div>

            {/* Login/Join */}
            <div className="sm:flex-1 flex items-center justify-end space-x-4">
              {/* <a href="#" className="text-white">Login</a>
              <a href="#" className="bg-blue-500 text-white px-4 py-2 rounded">Join</a> */}
              <a className='text-white cursor-pointer' onClick={() => setSearchOpen(!searchOpen)}><SearchIcon /></a>
            </div>
          </div>
        </nav>
        <div
          className={`fixed top-0 left-0 h-full w-full bg-gray-800 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden z-10`}
        >
          <div className="flex justify-end p-4">
            <button onClick={toggleMenu} className="text-white">
              <FaTimes />
            </button>
          </div>
          <nav className="flex flex-col items-center mt-10">
            {categories.map((category, index) => (
              <div key={index} className="relative w-full">
                <a
                  href={category.link}
                  className={`text-white px-4 py-2 w-full block hover:bg-red-900 ${category.subcategories ? 'flex justify-between items-center' : ''}`}
                  onClick={() => category.subcategories && toggleDropdown(index)}
                >
                  {category.name}
                  {category.subcategories && <ExpandMoreIcon />}
                </a>
                {category.subcategories && (
                  <div className={`${dropdownOpen === index ? 'block' : 'hidden'} bg-gray-800 text-white mt-2 rounded shadow-lg w-full`}>
                    {category.subcategories.map((sub, subIndex) => (
                      <a
                        key={subIndex}
                        href="#"
                        className="block px-4 py-2 hover:bg-red-900"
                      >
                        {sub}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="bg-red-700 lg:block hidden">
        <div className="container mx-auto flex justify-center flex-wrap">
          {categories.map((category, index) => (
            <div key={index} className="relative">
              <a
                href={category.link}
                className={`text-white px-4 py-2 block`}
                onClick={() => category.subcategories && toggleDropdown(index)}
              >
                {category.name} {category.subcategories && <ExpandMoreIcon />}
              </a>
              {category.subcategories && (
                <div className={`${dropdownOpen === index ? 'block' : 'hidden'} w-40 absolute bg-gray-800 text-white mt-2 rounded shadow-lg`}>
                  {category.subcategories.map((sub, subIndex) => (
                    <a
                      key={subIndex}
                      href="#"
                      className="block px-4 py-2"
                    >
                      {sub}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {
        searchOpen &&
        <div className="mx-3 lg:mx-40 transition-all ease-in duration-300 border">
          <form onSubmit={handleSubmit} className='flex w-full' action="">
            <input className='flex-1 p-2' type="text" name='searchInput' onChange={(e) => setSearchInput(e.target.value)} placeholder='Search...' />
            <button onClick={handleSubmit} className='p-2 bg-red-500 text-white'>Search</button>
          </form>
        </div>
      }
    </>
  )
}

export default Navbar